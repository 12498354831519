import axios from "axios";

document.querySelectorAll(".store-positions-info, .job-requisition").forEach(el => {
    el.addEventListener("click", e => {
        dataLayer.push({ event: "view-job-click", category: "applicant-journey", action: "click", label: "view-job-details" });
    })
})

document.querySelectorAll(".Hero__form__button").forEach(el => {
    el.addEventListener("click", e => {
        const event = (document.querySelector(".page-home")) ? "find-job-click" : "search-job-click"
        dataLayer.push({ event, category: "applicant-journey", action: "click", label: "find-jobs" });
    })
})

document.querySelectorAll(".JobDetails__apply").forEach(el => {
    el.addEventListener("click", e => {
        dataLayer.push({ event: "apply-job-click", category: "applicant-journey", action: "click", label: "apply-job" });
    })
})

/* Job Requisition Search */
document.querySelectorAll("#search_category").forEach(categories => {
    const functions = document.getElementById("search_function")
    const zipCode = document.getElementById("searchlocation")
    const assertZipCode = () => {
        zipCode.disabled = false
        if (categories.value === "Support Center" || functions.value !== "Panda Restaurant Group") {
            zipCode.value = ""
            zipCode.disabled = true
        }
    }
    let selectedFunction = null
    const params = new URLSearchParams(window.location.search);
    categories.addEventListener("change", async e => {
        const response = await axios.get(`jobs/list-functions/${e.target.value}`)
        for (let i = functions.options.length - 1; i >= 0 ; i--) functions.options.remove(i)
        response.data.forEach(opt => {
            const el = document.createElement('option');
            el.value = opt;
            el.innerHTML = opt;
            functions.appendChild(el);
        })
        functions.value = selectedFunction ?? "Panda Restaurant Group"
        selectedFunction = null
        assertZipCode()
    })
    functions.addEventListener("change", assertZipCode)
    if (params.has("category")) {
        categories.value = params.get("category")
        // noinspection JSCheckFunctionSignatures
        categories.dispatchEvent(new Event('change'))
        if (params.has("jobfunction")) selectedFunction = params.get("jobfunction")
    }
})

document.querySelectorAll("#load-more-jobs").forEach(button => {
    button.addEventListener("click", async e => {
        const res = await axios.get("/jobs/load-more")
        if (!res.data) return document.getElementById("load-more-jobs").remove()
        document.getElementById("job-list").innerHTML += res.data
    })
})
